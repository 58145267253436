import type { LucideProps } from 'lucide-react';

export const UpTriangle = ({ size = 24, color = '#13BE80' }: LucideProps) => {
	return (
		<svg
			style={{
				width: size,
				height: size,
			}}
			width={size}
			height={size}
			viewBox="0 0 8 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.56699 0.75C3.75944 0.416666 4.24056 0.416667 4.43301 0.75L7.03109 5.25C7.22354 5.58333 6.98298 6 6.59808 6H1.40192C1.01702 6 0.776461 5.58333 0.968911 5.25L3.56699 0.75Z"
				fill={color}
			/>
		</svg>
	);
};
