import type { LucideProps } from 'lucide-react';

export const Workflow = ({ size, color }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
		>
			<path
				opacity="0.4"
				d="M11 4.5H18.3C19.4201 4.5 19.9802 4.5 20.408 4.71799C20.7843 4.90973 21.0903 5.21569 21.282 5.59202C21.5 6.01984 21.5 6.5799 21.5 7.7V9C21.5 9.93188 21.5 10.3978 21.3478 10.7654C21.1448 11.2554 20.7554 11.6448 20.2654 11.8478C19.8978 12 19.4319 12 18.5 12M13 19.5H5.7C4.5799 19.5 4.01984 19.5 3.59202 19.282C3.21569 19.0903 2.90973 18.7843 2.71799 18.408C2.5 17.9802 2.5 17.4201 2.5 16.3V15C2.5 14.0681 2.5 13.6022 2.65224 13.2346C2.85523 12.7446 3.24458 12.3552 3.73463 12.1522C4.10218 12 4.56812 12 5.5 12"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.5 10.3C9.5 10.02 9.5 9.87996 9.5545 9.773C9.60243 9.67892 9.67892 9.60243 9.773 9.5545C9.87996 9.5 10.02 9.5 10.3 9.5H13.7C13.98 9.5 14.12 9.5 14.227 9.5545C14.3211 9.60243 14.3976 9.67892 14.4455 9.773C14.5 9.87996 14.5 10.02 14.5 10.3V13.7C14.5 13.98 14.5 14.12 14.4455 14.227C14.3976 14.3211 14.3211 14.3976 14.227 14.4455C14.12 14.5 13.98 14.5 13.7 14.5H10.3C10.02 14.5 9.87996 14.5 9.773 14.4455C9.67892 14.3976 9.60243 14.3211 9.5545 14.227C9.5 14.12 9.5 13.98 9.5 13.7V10.3Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17 17.8C17 17.52 17 17.38 17.0545 17.273C17.1024 17.1789 17.1789 17.1024 17.273 17.0545C17.38 17 17.52 17 17.8 17H21.2C21.48 17 21.62 17 21.727 17.0545C21.8211 17.1024 21.8976 17.1789 21.9455 17.273C22 17.38 22 17.52 22 17.8V21.2C22 21.48 22 21.62 21.9455 21.727C21.8976 21.8211 21.8211 21.8976 21.727 21.9455C21.62 22 21.48 22 21.2 22H17.8C17.52 22 17.38 22 17.273 21.9455C17.1789 21.8976 17.1024 21.8211 17.0545 21.727C17 21.62 17 21.48 17 21.2V17.8Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 2.8C2 2.51997 2 2.37996 2.0545 2.273C2.10243 2.17892 2.17892 2.10243 2.273 2.0545C2.37996 2 2.51997 2 2.8 2H6.2C6.48003 2 6.62004 2 6.727 2.0545C6.82108 2.10243 6.89757 2.17892 6.9455 2.273C7 2.37996 7 2.51997 7 2.8V6.2C7 6.48003 7 6.62004 6.9455 6.727C6.89757 6.82108 6.82108 6.89757 6.727 6.9455C6.62004 7 6.48003 7 6.2 7H2.8C2.51997 7 2.37996 7 2.273 6.9455C2.17892 6.89757 2.10243 6.82108 2.0545 6.727C2 6.62004 2 6.48003 2 6.2V2.8Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
