import type { LucideProps } from 'lucide-react';

export const DownTriangle = ({ size = 24, color = '#e57e18' }: LucideProps) => {
	return (
		<svg
			style={{
				width: size,
				height: size,
			}}
			width={size}
			height={size}
			viewBox="0 0 8 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.56699 5.25C3.75944 5.58333 4.24056 5.58333 4.43301 5.25L7.03109 0.75C7.22354 0.416667 6.98298 0 6.59808 0H1.40192C1.01702 0 0.776461 0.416667 0.968911 0.75L3.56699 5.25Z"
				fill={color}
			/>
		</svg>
	);
};
