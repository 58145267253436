import type { LucideProps } from 'lucide-react';

export const Phone = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				d="M8.38067 8.85323C9.07667 10.3028 10.0255 11.6615 11.227 12.8631C12.4286 14.0646 13.7873 15.0134 15.2369 15.7094C15.3616 15.7693 15.4239 15.7992 15.5028 15.8222C15.7832 15.904 16.1274 15.8453 16.3648 15.6752C16.4317 15.6274 16.4888 15.5702 16.6031 15.4559C16.9527 15.1063 17.1275 14.9315 17.3033 14.8172C17.9662 14.3862 18.8208 14.3862 19.4837 14.8172C19.6595 14.9315 19.8343 15.1063 20.1839 15.4559L20.3787 15.6508C20.9102 16.1822 21.1759 16.448 21.3202 16.7333C21.6073 17.3009 21.6073 17.9712 21.3202 18.5387C21.1759 18.8241 20.9102 19.0898 20.3787 19.6213L20.2211 19.7789C19.6915 20.3085 19.4267 20.5733 19.0666 20.7756C18.6671 21 18.0466 21.1614 17.5884 21.16C17.1755 21.1588 16.8932 21.0787 16.3288 20.9185C13.2954 20.0575 10.433 18.433 8.04506 16.045C5.65708 13.6571 4.0326 10.7947 3.17163 7.76131C3.01143 7.19687 2.93132 6.91464 2.93009 6.5017C2.92873 6.04347 3.09009 5.42298 3.31451 5.02348C3.51676 4.66345 3.78157 4.39863 4.31119 3.86901L4.46883 3.71138C5.00027 3.17993 5.26599 2.91421 5.55137 2.76987C6.11893 2.4828 6.7892 2.4828 7.35676 2.76987C7.64214 2.91421 7.90786 3.17993 8.4393 3.71138L8.63417 3.90625C8.98378 4.25585 9.15858 4.43065 9.27287 4.60643C9.70386 5.26932 9.70386 6.1239 9.27287 6.78679C9.15858 6.96257 8.98378 7.13738 8.63417 7.48698C8.51986 7.60129 8.46271 7.65845 8.41487 7.72526C8.24485 7.96269 8.18615 8.30695 8.26788 8.5873C8.29087 8.6662 8.32081 8.72854 8.38067 8.85323Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
