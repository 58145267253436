import {
	AlignJustify,
	Angry,
	ArrowBigDown,
	ArrowBigUp,
	ArrowDown,
	ArrowDownToLine,
	ArrowLeft,
	ArrowLeftCircle,
	ArrowRight,
	ArrowRightCircle,
	ArrowUp,
	Award,
	BadgeCheck,
	BadgeDollarSign,
	Ban,
	Bell,
	BookCheck,
	Box,
	Calendar,
	CaptionsOff,
	Check,
	ChevronDown,
	ChevronLeft,
	ChevronRight,
	ChevronUp,
	ChevronsUpDown,
	CircleCheckBig,
	CircleDollarSign,
	CircleEllipsis,
	CircleHelp,
	CircleOff,
	Clock,
	Cog,
	Cone,
	Copy,
	CreditCard,
	Ellipsis,
	ExternalLink,
	Eye,
	EyeOff,
	FileDown,
	FileLineChart,
	Filter,
	Fingerprint,
	FlaskConical,
	Frown,
	Gauge,
	GaugeCircle,
	Globe,
	GripHorizontal,
	History,
	Inbox,
	InboxIcon,
	Info,
	KeyRound,
	Landmark,
	Laugh,
	LayoutGrid,
	Lightbulb,
	LineChart,
	ListMinus,
	ListTree,
	LogOut,
	Mail,
	Maximize2,
	Meh,
	MessageCircleCode,
	MessageSquare,
	MessageSquareMore,
	MessagesSquare,
	Minimize2,
	Minus,
	MonitorCheck,
	Moon,
	MousePointerClick,
	MoveDown,
	MoveUp,
	MoveUpRight,
	Pause,
	Pencil,
	Phone,
	PlayIcon,
	Plus,
	PlusCircle,
	PrinterCheck,
	Receipt,
	Rocket,
	RotateCcw,
	Save,
	ScanSearch,
	Search,
	SendHorizontal,
	Settings,
	ShoppingBag,
	ShoppingCart,
	Smile,
	Star,
	Store,
	Sun,
	Table,
	Tag,
	Trash,
	User,
	UserSquare,
	Users,
	VenetianMask,
	Waypoints,
	Workflow,
	Wrench,
	X,
	Zap,
} from 'lucide-react';

import { cn } from '../../utilities';
import * as customIcons from './custom';
import { DownTriangle } from './custom/down-triangle';
import { Front } from './custom/front';
import { Shopify } from './custom/shopify';
import { StarFill } from './custom/star';
import { UpTriangle } from './custom/up-triangle';
import type { IconProps, IconWrapperProps } from './icon.types';

export const icons = {
	...customIcons,
	Cross: X,
	ChevronUp,
	ChevronDown,
	// Calendar,
	GaugeCircle,
	Workflow,
	MessagesSquare,
	Landmark,
	Cone,
	ArrowDown,
	ArrowUp,
	SendHorizontal,
	// Tag,
	CircleDollarSign,
	Cog,
	Thunder: Zap,
	// PlusCircle,
	ChevronLeft,
	ChevronRight,
	Wrench,
	FlaskConical,
	LineChart,
	PlayIcon,
	// Clock,
	// MessageSquare,
	Check,
	Save,
	Search,
	ArrowLeftCircle,
	ArrowRightCircle,
	Info,
	Copy,
	// ShoppingCart,
	BadgeDollarSign,
	ArrowDownToLine,
	UserSquare,
	BadgeCheck,
	ExternalLink,
	ListMinus,
	Store,
	Globe,
	Star,
	MoveUp,
	MoveDown,
	ArrowBigUp,
	ArrowBigDown,
	Eye,
	EyeOff,
	Mail,
	KeyRound,
	Front,
	// LogOut,
	FileDown,
	// ShoppingBag,
	Moon,
	Sun,
	Gauge,
	Rocket,
	Receipt,
	StarFill,
	ArrowLeft,
	ArrowRight,
	Shopify,
	History,
	FileLineChart,
	Table,
	VenetianMask,
	UpTriangle,
	DownTriangle,
	Trash,
	ListTree,
	// Filter,
	// Inbox,
	// Settings,
	Award,
	// User,
	CircleOff,
	RotateCcw,
	Ban,
	// Plus,
	Pause,
	CircleEllipsis,
	Ellipsis,
	Phone,
	ChevronsUpDown,
	Maximize2,
	ScanSearch,
	CircleHelp,
	Fingerprint,
	Minimize2,
	Angry,
	Frown,
	Meh,
	Laugh,
	Smile,
	MousePointerClick,
	CaptionsOff,
	CircleCheckBig,
	MessageCircleCode,
	PrinterCheck,
	Pencil,
	Minus,
	AlignJustify,
	LayoutGrid,
	Users,
	Box,
	CreditCard,
	BookCheck,
	MoveUpRight,
	Flow: Waypoints,
	MessageSquareMore,
	Waypoints,
	GripHorizontal,
	Lightbulb,
	// Bell,
	MonitorCheck,
	InboxIcon,
};

export const Icon = ({ size, currentColor, className, target, ...prop }: IconProps) => {
	const { color, ...restProps } = prop;
	const IconComponent = icons[prop.name];

	const isCustomSize = typeof size === 'number';

	const iconDefaultSize = size || 'default';

	const iconSize: Record<typeof iconDefaultSize, number> = {
		default: 18,
		xs: 12,
		sm: 16,
		md: 18,
		lg: 24,
		xl: 32,
		'2xl': 48,
		'3xl': 64,
		'4xl': 48,
	};

	const Wrapper = ({ children }: { children: React.ReactNode }) => {
		return children;
	};

	return (
		<Wrapper>
			<IconComponent
				size={isCustomSize ? size : iconSize[iconDefaultSize]}
				className={cn([!currentColor && 'text-neutrals-text-primary', color && `text-${color}`, className])}
				{...restProps}
			/>
		</Wrapper>
	);
};

export const IconWrapper = ({
	backgroundColor,
	className,
	rounded,
	borderColor,
	onClick,
	...iconProps
}: IconWrapperProps) => {
	return (
		<div
			onClick={onClick}
			className={cn([
				onClick && 'cursor-pointer',
				rounded ? 'rounded-full' : 'rounded-sm',
				backgroundColor ? `bg-${backgroundColor}` : 'bg-background',
				borderColor && `border border-${borderColor}`,
				className,
			])}
		>
			<Icon {...iconProps} />
		</div>
	);
};
