import type { LucideProps } from 'lucide-react';

export const StarFill = ({ size }: LucideProps) => {
	return (
		<svg
			style={{
				width: size,
				height: size,
			}}
			width="20"
			height="19"
			viewBox="0 0 20 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.55165 0.908315C9.73506 0.536731 10.2649 0.536731 10.4484 0.908315L12.9738 6.02454C13.0465 6.17197 13.1871 6.2742 13.3498 6.29798L18.9981 7.12356C19.4081 7.18348 19.5715 7.6874 19.2747 7.97648L15.1884 11.9565C15.0705 12.0714 15.0166 12.2369 15.0445 12.3992L16.0087 18.0213C16.0788 18.4298 15.65 18.7413 15.2832 18.5484L10.2327 15.8924C10.087 15.8158 9.91297 15.8158 9.76727 15.8924L4.71682 18.5484C4.35 18.7413 3.92123 18.4298 3.99129 18.0213L4.95554 12.3992C4.98337 12.2369 4.92954 12.0714 4.81161 11.9565L0.725339 7.97648C0.428542 7.6874 0.591934 7.18348 1.00189 7.12356L6.65018 6.29798C6.81286 6.2742 6.95345 6.17197 7.02622 6.02455L9.55165 0.908315Z"
				fill="#FFB905"
			/>
		</svg>
	);
};
