import type { LucideProps } from 'lucide-react';

export const Minimize = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M14 10L21 3M3 21L10 14"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 14H10V20M20 10H14V4"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
