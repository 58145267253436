import type { LucideProps } from 'lucide-react';

export const Bell = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.7965 6.23856C14.232 5.78864 14.5 5.17562 14.5 4.5C14.5 3.11929 13.3807 2 12 2C10.6193 2 9.5 3.11929 9.5 4.5C9.5 5.17562 9.768 5.78864 10.2035 6.23856M18 11.2C18 9.82087 17.3679 8.49823 16.2426 7.52304C15.1174 6.54786 13.5913 6 12 6C10.4087 6 8.88258 6.54786 7.75736 7.52304C6.63214 8.49823 6 9.82087 6 11.2C6 13.4818 5.43413 15.1506 4.72806 16.3447C3.92334 17.7056 3.52097 18.3861 3.53686 18.5486C3.55504 18.7346 3.58851 18.7933 3.73934 18.9036C3.87117 19 4.53351 19 5.85819 19H18.1418C19.4665 19 20.1288 19 20.2607 18.9036C20.4115 18.7933 20.445 18.7346 20.4631 18.5486C20.479 18.3861 20.0767 17.7056 19.2719 16.3447C18.5659 15.1506 18 13.4818 18 11.2Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
