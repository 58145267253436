import type { LucideProps } from 'lucide-react';

export const Send = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M21 3L10.5 13.5"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.41394 10.7444C2.8186 10.5128 2.52092 10.3971 2.43402 10.2303C2.35868 10.0857 2.35858 9.91342 2.43375 9.76873C2.52045 9.60183 2.81799 9.48572 3.41307 9.25349L20.3008 2.66315C20.838 2.45352 21.1066 2.34871 21.2782 2.40604C21.4273 2.45583 21.5442 2.5728 21.594 2.72185C21.6514 2.89348 21.5465 3.16207 21.3369 3.69925L14.7466 20.587C14.5143 21.1821 14.3982 21.4796 14.2313 21.5663C14.0866 21.6415 13.9144 21.6414 13.7698 21.566C13.603 21.4791 13.4872 21.1815 13.2557 20.5861L10.6276 13.8281C10.5806 13.7073 10.5571 13.6468 10.5208 13.596C10.4886 13.5509 10.4492 13.5114 10.4041 13.4793C10.3532 13.443 10.2928 13.4195 10.1719 13.3725L3.41394 10.7444Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
