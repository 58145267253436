import type { LucideProps } from 'lucide-react';

export const Inbox = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M22 15.2V13.1331C22 12.7105 22 12.4992 21.971 12.2923C21.9453 12.1085 21.9026 11.9275 21.8434 11.7515C21.7767 11.5535 21.6822 11.3645 21.4932 10.9865L19.3267 6.65337C18.8451 5.69024 18.6043 5.20859 18.2451 4.85675C17.9274 4.5456 17.5445 4.30896 17.1241 4.16396C16.6487 4 16.1103 4 15.0334 4H8.96656C7.88969 4 7.35125 4 6.87589 4.16396C6.45551 4.30896 6.07263 4.5456 5.75495 4.85675C5.3957 5.2086 5.1549 5.69019 4.67331 6.65337L2.50675 10.9865C2.31776 11.3645 2.22326 11.5535 2.15662 11.7515C2.09744 11.9275 2.05471 12.1085 2.02897 12.2923C2 12.4992 2 12.7105 2 13.1331V15.2"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 13.6C2 13.0399 2 12.7599 2.10899 12.546C2.20487 12.3578 2.35785 12.2049 2.54601 12.109C2.75992 12 3.03995 12 3.6 12H5.88197C6.56717 12 7.19357 12.3871 7.5 13C7.80643 13.6129 8.43283 14 9.11803 14H14.882C15.5672 14 16.1936 13.6129 16.5 13C16.8064 12.3871 17.4328 12 18.118 12H20.4C20.9601 12 21.2401 12 21.454 12.109C21.6422 12.2049 21.7951 12.3578 21.891 12.546C22 12.7599 22 13.0399 22 13.6V15.2C22 16.8802 22 17.7202 21.673 18.362C21.3854 18.9265 20.9265 19.3854 20.362 19.673C19.7202 20 18.8802 20 17.2 20H6.8C5.11984 20 4.27976 20 3.63803 19.673C3.07354 19.3854 2.6146 18.9265 2.32698 18.362C2 17.7202 2 16.8802 2 15.2V13.6Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
