/**
 * Calculates the increment percentage from a number to another
 * @param from The number to calculate the increment percentage from
 * @param to The number to calculate the increment percentage to
 * @returns The increment percentage from from to to
 */
export const incrementPercentage = (from: number, to: number, decimal = 2): number => {
	if (!from || !to) return 0;
	const inc = +to - +from;
	return +((+inc / +from) * 100).toFixed(decimal);
};
