import type { LucideProps } from 'lucide-react';

export const ArrowRight = ({ size, color }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
		>
			<path
				opacity="0.4"
				d="M2.66667 8H13.3333"
				stroke={color || 'currentColor'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.33334 4L13.3333 8L9.33334 12"
				stroke={color || 'currentColor'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
