import type { LucideProps } from 'lucide-react';

export const Tag = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M8 8H8.01M8.5 8C8.5 8.27614 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.27614 7.5 8C7.5 7.72386 7.72386 7.5 8 7.5C8.27614 7.5 8.5 7.72386 8.5 8Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.93726 11.9373C2.59135 11.5914 2.4184 11.4184 2.29472 11.2166C2.18506 11.0376 2.10425 10.8425 2.05526 10.6385C2 10.4083 2 10.1637 2 9.67451L2 5.2C2 4.07989 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.7157 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2L9.67452 2C10.1637 2 10.4083 2 10.6385 2.05526C10.8425 2.10425 11.0376 2.18506 11.2166 2.29472C11.4184 2.4184 11.5914 2.59135 11.9373 2.93726L19.6059 10.6059C20.7939 11.7939 21.388 12.388 21.6105 13.0729C21.8063 13.6755 21.8063 14.3245 21.6105 14.927C21.388 15.612 20.7939 16.2061 19.6059 17.3941L17.3941 19.6059C16.2061 20.7939 15.612 21.388 14.927 21.6105C14.3245 21.8063 13.6755 21.8063 13.0729 21.6105C12.388 21.388 11.7939 20.7939 10.6059 19.6059L2.93726 11.9373Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
