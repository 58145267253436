export const colorScheme = [
	{
		id: 'blue' as const,
		color: '#5FD8FF',
	},
	{
		id: 'purple' as const,
		color: '#8851FF',
	},
	{
		id: 'green' as const,
		color: '#15be80',
	},
	{
		id: 'orange' as const,
		color: '#F96E6E',
	},
	{
		id: 'pink' as const,
		color: '#EF2AA1',
	},
	{
		id: 'yellow' as const,
		color: '#ffc700',
	},
	{
		id: 'green' as const,
		color: '#15be80',
	},
	{
		id: 'cyan' as const,
		color: '#5FFFE4',
	},
];
