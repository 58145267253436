import type { LucideProps } from 'lucide-react';

export const ShoppingBag = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M16.2834 21C18.0942 21 18.9996 21 19.6679 20.6382C20.255 20.3204 20.7194 19.8157 20.9874 19.2042C21.2924 18.5082 21.2172 17.6059 21.0669 15.8014L20.3669 7.40138C20.2375 5.84875 20.1728 5.07243 19.829 4.48486C19.5262 3.96744 19.0753 3.5526 18.5346 3.29385C17.9205 3 17.1415 3 15.5834 3L8.41672 3C6.8587 3 6.07969 3 5.46559 3.29384C4.92482 3.5526 4.47397 3.96744 4.1712 4.48486C3.82737 5.07243 3.76268 5.84875 3.63329 7.40138L2.93329 15.8014C2.78292 17.6059 2.70773 18.5082 3.01276 19.2042C3.28076 19.8157 3.74511 20.3204 4.33226 20.6382C5.00055 21 5.90594 21 7.71671 21H16.2834Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
