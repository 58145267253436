import type { LucideProps } from 'lucide-react';

export const ArrowLeft = ({ size, color }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
		>
			<path
				opacity="0.4"
				d="M13.3327 8.79004H2.66602"
				stroke={color || 'currentColor'}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M6.66602 12.79L2.66602 8.79004L6.66602 4.79004"
				stroke={color || 'currentColor'}
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
