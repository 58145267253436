import type { LucideProps } from 'lucide-react';

export const FaceSad = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 16C16 16 14.5 14 12 14C9.5 14 8 16 8 16M17 9.23999C16.605 9.72499 16.065 9.99999 15.5 9.99999C14.935 9.99999 14.41 9.72499 14 9.23999M10 9.23999C9.605 9.72499 9.065 9.99999 8.5 9.99999C7.935 9.99999 7.41 9.72499 7 9.23999"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
