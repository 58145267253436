import type { LucideProps } from 'lucide-react';

export const CursorClick = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M9 3.5V2M5.06066 5.06066L4 4M5.06066 13L4 14.0607M13 5.06066L14.0607 4M3.5 9H2"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20.817 13.3728C21.3457 13.0881 21.61 12.9458 21.6768 12.7746C21.7349 12.626 21.7185 12.4586 21.6328 12.324C21.534 12.169 21.2471 12.0806 20.6733 11.9039L9.44528 8.44522C8.97653 8.30083 8.74216 8.22863 8.58386 8.28672C8.44598 8.33732 8.33732 8.44598 8.28672 8.58386C8.22863 8.74216 8.30083 8.97653 8.44522 9.44528L11.9038 20.6733C12.0806 21.2471 12.169 21.534 12.324 21.6328C12.4585 21.7185 12.6259 21.7349 12.7745 21.6769C12.9458 21.61 13.0881 21.3457 13.3728 20.8171L15.8645 16.1896C15.9068 16.111 15.928 16.0718 15.9551 16.0374C15.9793 16.0069 16.0069 15.9793 16.0374 15.9551C16.0718 15.928 16.111 15.9068 16.1896 15.8645L20.817 13.3728Z"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
