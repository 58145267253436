import type { LucideProps } from 'lucide-react';

export const Front = ({ size }: LucideProps) => {
	return (
		<svg
			style={{
				width: size,
				height: size,
			}}
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 2C0 0.89543 0.895431 0 2 0H11V3C11 4.10457 10.1046 5 9 5H7C5.89543 5 5 5.89543 5 7V14C5 15.1046 4.10457 16 3 16H0V5V4V2Z"
				fill="#868686"
			/>
			<circle cx="6.5" cy="6.5" r="3.5" fill="#DCDCDC" fillOpacity="0.62" />
		</svg>
	);
};
