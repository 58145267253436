import type { LucideProps } from 'lucide-react';

export const Node = ({ size, color }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
		>
			<path
				opacity="0.5"
				d="M19.9668 23.983C19.0037 23.8887 18.1032 23.3627 17.4848 22.5641C16.7638 21.6322 16.7353 20.5423 16.5729 19.4046C16.4176 18.322 15.7522 17.3597 14.9372 16.7321C13.0337 15.2684 10.3066 15.2698 8.57257 17.1351C8.03541 17.7148 7.67778 18.4612 7.48116 19.2467C7.29023 20.009 7.3743 20.8032 7.09218 21.5351C6.5764 22.8742 5.38383 23.8453 4.0388 23.9815C1.74912 24.2119 -0.161563 22.1829 0.0108402 19.7264C0.0963292 18.5003 0.734647 17.364 1.69212 16.6959C2.22501 16.3234 2.88612 16.0858 3.54296 16.0742C4.25822 16.0611 4.95068 16.3423 5.67021 16.2727C6.54078 16.1872 7.38142 15.6916 7.88296 14.9162C8.57827 14.1553 9.00571 13.1104 9.00571 11.9611C9.00571 10.1335 7.61651 7.88133 5.807 7.68712C5.10029 7.61176 4.42635 7.91756 3.72676 7.9364C1.82036 7.98712 0.151897 6.3045 0.0108402 4.27259C-0.161563 1.81894 1.74627 -0.210067 4.03595 0.0174721C5.65169 0.179793 6.99672 1.51169 7.30306 3.22765C7.3344 3.40302 7.35577 3.57838 7.36432 3.75085C7.49683 6.23783 9.61838 8.73641 11.9993 8.73641C13.3115 8.73641 14.5012 8.048 15.3276 6.96827C16.1811 5.85232 16.4475 4.60448 16.694 3.22765C17.0004 1.51024 18.3454 0.178343 19.9611 0.0174721C22.2508 -0.210067 24.1586 1.81604 23.9877 4.27259C23.8566 6.15957 22.4603 7.71466 20.712 7.91321C19.84 8.01176 19.0008 7.57987 18.1402 7.71321C16.2181 8.01321 14.6836 9.92338 14.6836 12.0002C14.6836 13.3901 15.3376 14.7133 16.4062 15.493C16.9505 15.8901 17.576 16.2539 18.2442 16.2785C18.9937 16.3061 19.7218 16.0437 20.4712 16.0713C22.3064 16.1379 23.8523 17.7597 23.9891 19.7249C24.1601 22.1815 22.2551 24.2076 19.9668 23.983Z"
				fill={color || 'currentColor'}
			/>
			<ellipse cx="3.681" cy="3.97329" rx="3.681" ry="3.97329" fill={color || 'currentColor'} />
			<ellipse cx="3.681" cy="20.0379" rx="3.681" ry="3.96205" fill={color || 'currentColor'} />
			<ellipse cx="20.3191" cy="3.97329" rx="3.681" ry="3.97329" fill={color || 'currentColor'} />
			<ellipse cx="20.3189" cy="20.0379" rx="3.681" ry="3.96205" fill={color || 'currentColor'} />
		</svg>
	);
};
