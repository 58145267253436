import type { LucideProps } from 'lucide-react';

export const Link01 = ({ size, color, ...restProps }: LucideProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ width: size, height: size }}
			{...restProps}
		>
			<path
				opacity="0.4"
				d="M8.5 15.5L15.5 8.5"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.7071 18.364L11.2929 19.7782C9.34027 21.7308 6.17444 21.7308 4.22182 19.7782C2.2692 17.8256 2.2692 14.6598 4.22182 12.7071L5.63603 11.2929M18.364 12.7071L19.7782 11.2929C21.7308 9.3403 21.7308 6.17447 19.7782 4.22185C17.8255 2.26923 14.6597 2.26923 12.7071 4.22185L11.2929 5.63607"
				stroke={color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
